import React, { useEffect } from 'react'

import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import * as styles from './404.module.css'

const browser = typeof window !== "undefined";

const NotFoundPage = () => {
  useEffect(() => {
    try {
      if (browser) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
      }
    } catch (e) {
      console.error(e);
    }
  }, [])

  return (
    browser && (
      <Layout>
        <SEO title="Sivua ei löytyny" />
        <h1>Voi ei, sivua ei löytynyt!</h1>
        <p>Etsi ensin etusivun haulla uudelleen koulusi tai kaupunkisi nimellä, <strong><i>osoite on voinut muuttua</i></strong>. Jos se löytyy, päivitä pikalinkkisi osoittamaan toimivaan sivuun.</p>
        <p>Voi myös olla, että koulu ei ole vielä julkaissut seuraavan viikon ruokalistaa, odottele pari päivää.</p>
        <p>Jos ei ole lomaviikkokaan ja ruokalista puuttuu silti, lähetä meille <Link to="/palaute/">palautetta</Link> ja kerro mikä sivu on hukassa niin etsimme!</p>
        <h1>╯︿╰</h1>
        <div id="adbanner" className={styles.adBannerParent}>
          <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
            data-ad-client="ca-pub-2668838512868610"
            data-ad-slot="5745330552"
            data-ad-format="horizontal,auto"
            data-full-width-responsive="true"></ins>
        </div>
      </Layout>
    )
  );
};

export default NotFoundPage
